$primary-color: #CCFF90;
$secondary-color: #ECEFF1;
$text-color: #000;

$h1-size: 2rem; // TO BE UPDATED 
$h2-size: 1.4rem; // TO BE UPDATED 

@font-face {
    font-family: "Neue Plak";
    src: url("../assets/fonts/NeuePlak-ExtendedBold.woff") format("woff");
    font-weight: bold;
}
  
@font-face {
    font-family: "NB International Regular";
    src: url("../assets/fonts/NB-International-Pro-Regular.woff") format("woff");
}

@font-face {
    font-family: "Krisha";
    src: url("../assets/fonts/Krisha-Regular.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$title-font: "Krisha";
$heading-font: "Neue Plak";
$p-font: "NB International Regular";
$sub-font: "Inter";

h1, 
h2, 
h3, 
h4, 
h5, 
h6, 
p,
ul {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 2rem;
}

h2, h3 {
    font-family: $heading-font;
}

h2 {
    font-size: 2.0rem;
    color: $primary-color;
}

h3 {
    font-size: 4 rem;
}

p {
    font-family: $p-font;
    font-size: 0.9rem;
    color: $primary-color;
}

span {
    font-family: $p-font;
}

@media only screen and (max-width: 1300px) {
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 500px) {
    h3 {
        font-size: 1.2rem;
    }
}