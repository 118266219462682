@import '../../styles/base.scss';

.sidebar {

    .background {
        background-color: $secondary-color;
        height: 100vh;
        width: 35vw;
        position: fixed;
        z-index: 99;
    }
    .measurement-tool {
        position: absolute;
        width: 1vw;
        height: 100px;
    }
    
    .container-img-overflow {
        position: absolute;
        right: 8vw;
        top: 35vw;
        overflow: hidden;
        height: 22vw;

        .container-img {
            display: flex;
            flex-direction: column;

            .flower3 {
                position: relative;
                height: 20vw;
                transform: translateY(24vw);
            }
            .flower5 {
                position: relative;
                height: 20vw;
                transform: translateY(28vw);
            }
        }
    }
    .card {
        color: $text-color;
        padding: 2rem 8vw;
        text-align: left;
        height: 100vh;
        position: fixed;
        z-index: 100;

        h1 {
            font-family: $title-font;
            font-size: 14vw;
            font-weight: normal;
            letter-spacing: 3px;
            color: $primary-color;
            -webkit-text-stroke-width: 1.25px;
            -webkit-text-stroke-color: black;
        }
        h2 {
            font-family: $heading-font;
            font-weight: normal;
            font-size: 4vw;
            line-height: 130%;
            letter-spacing: 4%;
            margin: 0;
            margin-bottom: 1rem;
            color: $text-color
        }
        h3 {
            font-family: $p-font;
            font-weight: normal;
            font-size: 1.9vw;
            line-height: 130%;
            margin: 0;
            margin-bottom: 2vw;
            color: $text-color
        }
        .container-title {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            .spike {
                display: flex;
                background-image: url("../../assets/images/blackspike.svg");
                background-size: contain;
                background-repeat: no-repeat;
                height: 6.75vw;
                width: 11.5vw;
        
                h4 {
                    font-family: $title-font;
                    font-size: 3vw;
                    color: $primary-color;
                    letter-spacing: 1px;
                    margin: auto;
                    font-weight: normal;
                }
            }
        }
        .flower {
            width: 10vw;
        }
        .margin {
            transform: translateY(-5vw);
        }
        .hero-title {
            display: inline;
        }
        #spike {
            position: relative;
        }
        .orange-spike {
            width: 180px;
            margin-bottom: 1.5rem;
        }
        .title {
            width: 30vw;
            margin-bottom: 1.5rem;
        }
        .subhead-box {
            overflow-y: hidden;
            position: absolute;
            height: 8vw;
            transform: translateY(-5vw);
        }
        .subhead-container {
            display: flex;
            flex-direction: column;
        }
    }
    @media only screen and (max-width: 800px) {
        .background {
            width: 40vw;
        }
    }
}