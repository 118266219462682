@import '../../styles/base.scss';

.FAQ {  
  max-width: 100vw;
  display: flex;
  color: $primary-color;
  background-color: $secondary-color;

  justify-content: space-between;
  text-align: left;
  padding: 4rem 8vw;

  .cont {
    margin-right: 5rem;
  }
  /*
  #nameContainer {
    margin-top: 3rem;
    margin-left: 3rem;
    margin-bottom: 0rem;
  }
  */
  .FAQ-Cont {
    text-align: start;
    width: 94vw;
    min-width: 1200px;
    margin-right: 0.2rem;
    margin-left: 0.8rem;
  }
  .faqgrid {  
    max-height: 24vh; 
    min-height: 200px;
  }
  .flowergrid{
    display: flex;
    justify-content: flex-end;
  }
  
  .flower {
    margin-right: -1.8rem;
    margin-top: 0rem;
    width: 380px;
    height: auto;
  }
  
  
  .FAQ-Cont h2 {
    font-family: $heading-font;
    color: black;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  
  
  }
  
  .FAQ-Cont p {
    font-family: $p-font;
    font-size: 1.1rem;
    color: black;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1300px) {
    #FAQ {
      max-width: auto;
      padding: 0;
    }
    .faqgrid {
      max-height: auto;
      min-height: auto;
    }
    .FAQ-Cont {
      width: 84vw;
    }
  }
}

