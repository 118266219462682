@import '../../styles/base.scss';

.landing {
    width: 38vw;
    color: $primary-color;
    background-color: $secondary-color;
    padding: 4rem 0 4rem 60vw;
    text-align: left;

    h1 {
        font-family: $title-font;
        font-size: 13rem;
        line-height: 80%;
        margin-bottom: 2.5rem;
        margin-top: 0;
        font-weight: normal;
        letter-spacing: 6px;
    }
    h2 {
        font-family: $p-font;
        font-weight: normal;
        font-size: 2rem;
        line-height: 130%;
    }
    h3 {
        font-family: $title-font;
        font-size: 3rem;
        font-weight: normal;
        position: absolute;
        display: flex;
        text-align: center;
        width: 100%;
        color: $secondary-color;
        margin-top: 3.5rem;
    }
    p {
        font-family: $p-font;
        font-size: 1.25rem;
        color: #141414;
        margin-bottom: 3rem;
    }
    .flower {
        width: 10vw;
    }
    .flower5 {
        width: 10vw;
        transform: scaleX(-1);
    }
    .hero-title {
        display: inline;
    }
    #spike {
        position: relative;
    }
    .orange-spike {
        width: 180px;
        margin-bottom: 1.5rem;
    }
}
