@import '../../styles/base.scss';

.navbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 120;
    padding: 0 8vw 1rem;

    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    li {
        display: flex;
    }

    a, p {
        text-decoration: none;
        font-family: $title-font;
        color: $text-color;
        font-size: 5vw;
        letter-spacing: 4px;
        cursor: pointer;
    }

    a:hover {
        text-decoration: underline;
    }

    p:hover {
        text-decoration: underline;
    }

    img {
        height: 3vw;
        align-self: center;
        margin-left: 4vw;
    }
}