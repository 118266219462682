@import '../../styles/base.scss';

.faq2 {
    display: flex;
    flex-direction: row;
    padding: 4rem 0 4rem 4vw;
    background-color: $secondary-color;
    height: 70vh;

    h3 {
        font-family: $heading-font;
        font-size: 1.3rem;
        color: $text-color;
        padding-bottom: 0.25rem;
    }
    p {
        display: inline;
        font-family: $p-font;
        font-size: 0.9rem;
        color: $text-color;
    }
    a {
        display: inline;
        font-family: $p-font;
        font-size: 0.9rem;
        color: $text-color;
    }
    .container {
        width: 60vw;
    }
    .items {
        padding-right: 2rem;
    }
    .flower {
        width: 15vw;
        object-fit: contain;
        padding-right: 3rem;
        transform: translateY(5vw);
    }

    @media only screen and (max-width: 1300px) {
        height: 65vh;
        h3 {
            font-size: 0.9rem;
        }
        p, a {
            font-size: 0.7rem;
        }
    }
    @media only screen and (max-width: 960px) {
        height: 72vh;

        h3 {
            font-family: $heading-font;
            font-size: 0.8rem;
        }
        p, a {
            font-size: 0.6rem;
        }
        .flower {
            width: 20vw;
            min-width: 150px;
            object-fit: contain;
            padding-right: 3rem;
            transform: translateY(5vw);
        }
    }
    @media only screen and (max-width: 760px) {
        h3 {
            font-family: $heading-font;
            font-size: 0.7rem;
        }
        p, a {
            font-size: 0.5rem;
        }
    }
    @media only screen and (max-width: 600px) {
        h3 {
            font-family: $heading-font;
            font-size: 0.6rem;
        }
        p, a {
            font-size: 0.35rem;
        }
    }
    
}
.faq-background {
    background-color: $secondary-color;
}