@import '../../styles/base.scss';

.mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 8vw;
    background-color: $secondary-color;
    color: $primary-color;
    border-bottom: 1.5px solid $text-color;
    z-index: 1000;

    .menu {
        height: 20px;
        object-fit: contain;
        z-index: 1000;
        cursor: pointer;
    }
    .logo {
        height: 24px;
        object-fit: contain;
    }
    .background {
        display: flex;
        justify-content: center;
        background-color: $secondary-color;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 6rem 0;

            a {
                font-family: $title-font;
                color: $text-color;
                text-decoration: none;
                font-size: 4rem;
                letter-spacing: 3px;
                text-align: center;
            }
        }
    }
}