@import '../../styles/base.scss';

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary-color;
    width: 70vw;
    h2 {
        margin: 7vh 0 1rem 0;
        width: 65%;
        text-align: left;
        color: $text-color;
        font-size: 1.3vw;
    }
    .timeline-h2 {
        display: none;
    }
    &__header {
        display: none;
        @media only screen and (max-width: 500px) {
            display: inline-block;
        }
    }

    &__table {
        width: 65%;
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 6fr;
        align-items: flex-start;
        justify-content: flex-start;
        > span {
            background-color: $text-color;
            color: white;
            height: 6vh;
            vertical-align: middle;
            padding: 0 2vh;
        }
        &__time {
            height: 6vh;
            background-color: $text-color;
            color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $text-color;
        }
        &__detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            > div {
                display: flex;
                align-items: center;
                // padding: 0 3rem;
                width: 100%;
                > h3 {
                    width: 100%;
                    text-align: left;
                    color: $text-color;
                    padding-bottom: 0.4rem;
                }
            }
            &__header {
                height: 6vh;
                padding: 0 2rem;
                background-color: $primary-color;
                border: 2px solid $text-color;
            }
            &__info {
                height: calc(6vh + 0.8rem);
                padding: 0.1rem 2rem;
                border: 2px solid $text-color;
                border-top: none;
                border-bottom: none;
                > p {
                    text-align: left;
                    color: $text-color;
                    font-size: 0.9rem;
                }
            }
        }
    }
    .flower4 {
        width: 0;
    }
    @media only screen and (max-width: 1300px) {
        min-width: 800px;
        h2 {
            padding-top: 1rem;
            text-align: left;
            color: $text-color;
            font-size: 1.75rem;
            margin: 5vh 0 1rem 0;
        }
        &__header {
            font-family: $title-font;
            font-size: 8rem;
            color: $primary-color;
            padding: 1rem 0 0;
            font-weight: normal;
            letter-spacing: 3px;
        }
        &__row {
            &__time {
                background-color: $text-color;
                color: $primary-color;
            }
            &__detail {
                > div {
                    > h3 {
                        color: $text-color;
                    }
                }
                &__info {
                    > p {
                        font-size: 0.7rem;
                    }
                }
            }
            
        }
    }
    @media only screen and (max-width: 800px) {
        &__row {
            grid-template-columns: 1fr 3fr;

            &__detail {
                &__info {
                    > p {
                        font-size: 0.5rem;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 500px) {
        padding: 0 2rem 1rem;
        width: calc(100vw - 6rem);
        min-width: 0;
        h2 {
            width: 100%;
            margin-top: 0;
        }
        .timeline-h2 {
            display: block;
            font-size: 4rem;
            font-family: $title-font;
            font-weight: 400;
            letter-spacing: 3px;
        }
        &__table {
            width: 100%;
        }
        &__row {
            grid-template-columns: 1fr 2fr;
            &__time {
                padding: 0rem 0;
            }
            &__detail {
                &__header {
                    padding: 0rem 1rem;
                }
                > div {
                    > h3 {
                        font-size: 4.2vw;
                    }
                }
                &__info {
                    padding: 1.5rem 1rem;

                    > p {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .flower4 {
            width: 40vw;
            margin-top: 1rem;
        }
    }
}
.timelineBackground {
    display: flex;
    flex-direction: row;
    background-color: $secondary-color;
    .flower5 {
        width: 30vw;
        object-fit: contain;
        min-width: 200px;
        transform: translateX(8vw);

        @media only screen and (max-width: 500px) {
            display: none;
        }
    }
}

