@import './base.scss';

html,body{
    padding:0;
    margin:0;
    scroll-behavior: smooth;
  }
  body {
    overflow-x: hidden;
    background-color: $secondary-color;
  }
  
  #container {
    display: inline-flex;
    height:100vh;
  }
  
  .module{
    display: flex;
    height:100vh;
  }
  
  .fixed{
    position: fixed;
    width: 60vw;
    background-color:green;
    z-index: 1;
  }
  
  .yellow{
    width:1300px;
    background-color:yellow;
  }
  
  .purple{
    width:800px;
    background-color:purple;
  }
  
  .orange{
    width:800px;
    background-color:purple;
  }
  
  .blue{
    width:2100px;
    background-color:blue;
  }
  
  .red{
    width:900px;
    background-color:red;
  }

.title-container {
  width: 30vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: $secondary-color;
}

.desktop-container {
    @media screen and (max-width: 500px) {
        display: none;
    }
}

.mobile-container {
    @media (min-width: 500px) {
        display: none;
    }
}