@import '../../styles/base.scss';

.mobile-faq {
    padding: 2rem 8vw;
    background-color: $text-color;

    h2 {
        font-family: $title-font;
        font-size: 4rem;
        color: $primary-color;
        padding: 1rem 0 4rem;
        font-weight: normal;
        letter-spacing: 3px;
    }
    
    .block {
        padding-bottom: 3rem;

        h3 {
            font-family: $heading-font;
            font-size: 1.5rem;
            color: $primary-color;
            padding-bottom: 1.25rem;
        }
        p {
            display: inline;
            font-family: $p-font;
            font-size: 1.2rem;
            color: $primary-color;
        }
        a {
            display: inline;
            font-family: $p-font;
            font-size: 1.2rem;
            color: $primary-color;
        }
    }
    .container-flower {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .flower-mini {
        position: relative;
        width: 10rem;
        object-fit: contain;
        transform: translateX(-4rem);
    }
    .flower-big {
        position: absolute;
        width: 15rem;
        object-fit: contain;
        right: -4rem;
    }
}